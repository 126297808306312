<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">Payment by wire transfer</h1>
        <button @click="$router.go(-1)" class="row align-end gap-10 mb-2">
          <s-icon color="white" height="20">chevron-left</s-icon>
          <div class="my-0">Back</div>
        </button>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center pt-50 pb-20 col">
    </section>

    <section class="w600 mx-auto">
      <div class="grid mb-20">
        <div v-if="bankInfo.accountName" class="label">Beneficiary</div>
        <div v-if="bankInfo.accountName" class="item">
          {{ bankInfo.accountName }}
        </div>
        <div v-if="bankInfo.IBAN" class="label">IBAN</div>
        <div v-if="bankInfo.IBAN" class="item">sdfsdf</div>
        <div v-if="bankInfo.bic" class="label">BIC</div>
        <div v-if="bankInfo.bic" class="item">{{ bankInfo.bic }}</div>
        <div v-if="bankInfo.name" class="label">Name</div>
        <div v-if="bankInfo.name" class="item">{{ bankInfo.name }}</div>
        <div v-if="bankInfo.accountNumber" class="label">AccountNumber</div>
        <div v-if="bankInfo.accountNumber" class="item">
          {{ bankInfo.accountNumber }}
        </div>
        <div v-if="bankInfo.bankCode" class="label">Bank Code</div>
        <div v-if="bankInfo.bankCode" class="item">
          {{ bankInfo.bankCode }}
        </div>
        <div v-if="bankInfo.currency" class="label">Currency</div>
        <div v-if="bankInfo.currency" class="item">
          {{ bankInfo.currency }}
        </div>
        <div v-if="bankInfo.address1" class="label">Address 1</div>
        <div v-if="bankInfo.address1" class="item">
          {{ bankInfo.address1 }}
        </div>
        <div v-if="bankInfo.address2" class="label">Address 2</div>
        <div v-if="bankInfo.address2" class="item">
          {{ bankInfo.address2 }}
        </div>
        <div v-if="bankInfo.ZIP" class="label">ZIP</div>
        <div v-if="bankInfo.ZIP" class="item">{{ bankInfo.ZIP }}</div>
        <div v-if="bankInfo.city" class="label">City</div>
        <div v-if="bankInfo.city" class="item">{{ bankInfo.city }}</div>
        <div v-if="bankInfo.state" class="label">State</div>
        <div v-if="bankInfo.state" class="item">{{ bankInfo.state }}</div>
        <div v-if="bankInfo.country" class="label">Country</div>
        <div v-if="bankInfo.country" class="item">{{ bankInfo.country }}</div>
      </div>
    </section>

    <vue-html2pdf
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Bank-Details-SecurCom"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section class="w600 mx-auto" slot="pdf-content">
        <div class="grid mb-20 black--text pt-50">
          <div v-if="bankInfo.accountName" class="label">Beneficiary</div>
          <div v-if="bankInfo.accountName" class="item">
            {{ bankInfo.accountName }}
          </div>
          <div v-if="bankInfo.IBAN" class="label">IBAN</div>
          <div v-if="bankInfo.IBAN" class="item">sdfsdf</div>
          <div v-if="bankInfo.bic" class="label">BIC</div>
          <div v-if="bankInfo.bic" class="item">{{ bankInfo.bic }}</div>
          <div v-if="bankInfo.name" class="label">Name</div>
          <div v-if="bankInfo.name" class="item">{{ bankInfo.name }}</div>
          <div v-if="bankInfo.accountNumber" class="label">AccountNumber</div>
          <div v-if="bankInfo.accountNumber" class="item">
            {{ bankInfo.accountNumber }}
          </div>
          <div v-if="bankInfo.bankCode" class="label">Bank Code</div>
          <div v-if="bankInfo.bankCode" class="item">
            {{ bankInfo.bankCode }}
          </div>
          <div v-if="bankInfo.currency" class="label">Currency</div>
          <div v-if="bankInfo.currency" class="item">
            {{ bankInfo.currency }}
          </div>
          <div v-if="bankInfo.address1" class="label">Address 1</div>
          <div v-if="bankInfo.address1" class="item">
            {{ bankInfo.address1 }}
          </div>
          <div v-if="bankInfo.address2" class="label">Address 2</div>
          <div v-if="bankInfo.address2" class="item">
            {{ bankInfo.address2 }}
          </div>
          <div v-if="bankInfo.ZIP" class="label">ZIP</div>
          <div v-if="bankInfo.ZIP" class="item">{{ bankInfo.ZIP }}</div>
          <div v-if="bankInfo.city" class="label">City</div>
          <div v-if="bankInfo.city" class="item">{{ bankInfo.city }}</div>
          <div v-if="bankInfo.state" class="label">State</div>
          <div v-if="bankInfo.state" class="item">{{ bankInfo.state }}</div>
          <div v-if="bankInfo.country" class="label">Country</div>
          <div v-if="bankInfo.country" class="item">{{ bankInfo.country }}</div>
        </div>
      </section>
    </vue-html2pdf>

    <section class="w600 mx-auto mt-20 row gap-20 space-between">

      <s-btn @click.native="generateReport" class="green">Download / print</s-btn>
      <router-link to="/licenses" class="green">
        <s-btn class="green">close</s-btn>
      </router-link>
    </section>
  </div>
</template>


<script>
import VueHtml2pdf from "vue-html2pdf";
import { mapState } from 'vuex';
export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      bankInfo: {},
    };
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  computed: {
    ...mapState('invoice', ['invoice']),
    id() {
      return this.$route.params.id;
    },
    options() {
      return { top: 20 };
    },
  },
  async mounted() {
    let res = await this.$store.dispatch("payment/wiretransfer", this.invoice.invoiceNumber);
    this.bankInfo = res.bankInfo;
  },
};
</script>

<style scoped>
.label {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
}

.item {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #42515e;
  border-right: 1px solid #42515e;
  border-top: 1px solid #42515e;
}

.grid {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
}
</style>